import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Background from '../components/background'
import CompanyInformation from '../components/company_information'
import CompanyInformationItem from '../components/company_information_item'

const ContactPage = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout footerColor="#ffffff">
          <SEO title={t('title')} language={i18n.language} />
          <Background title={t('contact-us-title')} backgroundText={t('contact-desc')} backgroundTextPic="bg-section-background-contact" />
          <CompanyInformation title={t('contact-us-addresses')}>
              <div className="company-information">
                  <div className="company-information-column">
                      <CompanyInformationItem name={t('info-aitia')} value={t('value-aitia')} mailto />
                      <CompanyInformationItem name={t('info-sales')} value={t('value-sales')} mailto />
                      <CompanyInformationItem name={t('info-research')} value={t('value-research')} mailto />
                      <CompanyInformationItem name={t('info-career')} value={t('value-career')} mailto />
                      <CompanyInformationItem name={t('info-speech')} value={t('value-speech')} mailto />
                      <CompanyInformationItem name={t('info-telecommunication')} value={t('value-telecommunication')} mailto />
                  </div>
              </div>
          </CompanyInformation>
      </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "contact", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

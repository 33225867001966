import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

const CardList = ({ translationKey, ulClassName }) => {
  const { t } = useTranslation()

  return (
      <p>
          <ul className={ulClassName || ''}>
              {t(translationKey, { returnObjects: true }).map((item) => (
                  <li>
                      <Trans i18nKey={item} components={[<strong />, <em />]} />
                  </li>
              ))}
          </ul>
      </p>
  )
}

CardList.propTypes = {
  translationKey: PropTypes.string.isRequired,
  ulClassName: PropTypes.string,
}

CardList.defaultProps = {
  ulClassName: undefined,
}

export default CardList

import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import CardList from './cardlist'

const Background = ({
  title, backgroundText, backgroundTextPic, big, translationKey,
}) => {
  const data = useStaticQuery(graphql`
    query SectionBackgroundImages {
        images: allFile(filter: { relativeDirectory: { eq: "divisions" } } ) {
            nodes {
                id
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
  `)

  const image = data.images.nodes.find((n) => n.childImageSharp.fluid.src.includes(backgroundTextPic))

  const renderBackgroundContent = () => {
    if (backgroundText) {
      return <p>{backgroundText}</p>
    }

    if (translationKey) {
      return (
          <CardList translationKey={translationKey} />
      )
    }

    return ''
  }

  return (
      <div className="page-section page-section-pattern">
          <div className="page-section-title page-section-title-large">
              <p>{title.toUpperCase()}</p>
          </div>
          <div className="page-section-content">
              <div className="page-section-content-background">
                  <div className="page-section-content-background-text">
                      {renderBackgroundContent()}
                  </div>
                  <div className={`page-section-content-background-img-wrapper${big ? '-big' : ''}`}>
                      <Img fluid={image.childImageSharp.fluid} />
                  </div>
              </div>
          </div>
      </div>
  )
}

Background.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundText: PropTypes.string,
  backgroundTextPic: PropTypes.string.isRequired,
  translationKey: PropTypes.string,
  big: PropTypes.bool,
}

Background.defaultProps = {
  big: undefined,
  backgroundText: undefined,
  translationKey: undefined,
}

export default Background

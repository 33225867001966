import React from 'react'
import PropTypes from 'prop-types'

const CompanyInformation = ({ title, children, grey }) => (
    <div className={`page-section ${grey ? '' : 'page-section-white'}`}>
        <div className="page-section-title">
            <p>{title.toUpperCase()}</p>
        </div>
        <div className="page-section-content">
            {children}
        </div>
    </div>
)

CompanyInformation.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  grey: PropTypes.bool,
}

CompanyInformation.defaultProps = {
  grey: undefined,
}

export default CompanyInformation

import React from 'react'
import PropTypes from 'prop-types'

const renderItemType = (mailto, tel, value) => {
  if (mailto) {
    return <a className="company-information-item-mailto" href={`mailto:${value}`}>{value}</a>
  }

  if (tel) {
    return <a className="company-information-item-mailto" href={`tel:${value}`}>{value}</a>
  }

  return <span className="company-information-item-value">{value}</span>
}

const CompanyInformationItem = ({
  name, value, mailto, tel,
}) => (
    <div className="company-information-item">
        <span className="company-information-item-key">{name}</span>
        :
        {' '}
        {renderItemType(mailto, tel, value)}
    </div>
)

CompanyInformationItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  mailto: PropTypes.bool,
  tel: PropTypes.bool,
}

CompanyInformationItem.defaultProps = {
  mailto: undefined,
  tel: undefined,
}

export default CompanyInformationItem
